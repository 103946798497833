<template>
  <div class="mb-20">
    <h1 class="text-dark-cl-20 text-1xl sm:text-3xl mb-20">Email Confirmation</h1>
    <p class="text-grey-fp-60 mb-20">
      <span>
        We've sent an email to
      </span>
      <span class="text-primary">
        {{ userEmail }}
      </span>
    </p>
    <p class="text-grey-fp-60">Please confirm your email by entering the confirmation code below</p>
  </div>

  <!-- F O R M -->
  <div>
    <form @submit.prevent="onSubmit">
      <AppInput
        v-model="v$.verificationCode.$model"
        name="code"
        label="Enter Code"
        class="mb-25 sm:mb-40"
        :size="screen.width < 640 ? 'medium' : 'large'"
        :error="errorMessages.verificationCode"
        @focus="clearErrorField('verificationCode', errorMessages)"
      />
      <p class="mb-10">
        <span
          v-if="showChangeEmailHandler"
          class="text-primary cursor-pointer"
          @click="$emit('change-email-click')"
        >
          Change Email
        </span>
      </p>

      <!-- A C T I O N S -->
      <AppButton
        type="primary"
        :size="screen.width < 640 ? 'medium' : 'large'"
        class="w-full mb-15"
      >
        Confirm
      </AppButton>
    </form>
    <div class="flex justify-center items-center mb-20">
      <p class="text-grey-fp-60">
        <span>Didn't get an Email?</span>
        <span
          class="text-primary cursor-pointer"
          @click="resendCode"
        >
          Resend
        </span>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { useVuelidate } from "@vuelidate/core";
  import { useScreen } from 'vue-screen';

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppButton from '@/components/stateless/AppButton.vue';

  import { vuex } from '@/store';
  import { IErrorFields } from '@/types';
  import { verificationFormRules } from '@/views/auth/auth-validation-rules';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { authService } from '@/services';
  import { routesNames } from '@/router';

  export default defineComponent({
    name: 'Verification',

    components: { AppInput, AppButton },

    props: {
      userEmail: {
        type: String,
        required: true
      },
      
      showChangeEmailHandler: {
        type: Boolean,
        default: false
      }
    },

    emits: [ 'change-email-click' ],

    setup(props) {
      const router = useRouter();
      const screen = useScreen();
      const state = reactive<{ verificationCode: string }>({ verificationCode: '' });
      const rules = computed<any>(() => verificationFormRules);
      const v$ = useVuelidate(rules, state);

      const errorMessages = reactive<IErrorFields>({ verificationCode: '' });

      async function onSubmit () {
        if (await v$.value.$validate()) {
          vuex.setLoading(true);

          authService.confirmSignUp(props.userEmail, state.verificationCode)
            .then(() => router.push({ name: routesNames.greeting }))
            .catch((err) => { errorMessages.verificationCode = !err.message ? err : err.message; } ); 

        } else {
          handleSetErrors(v$.value.$errors, errorMessages);
        }
      }

      async function resendCode() {
        return await authService.resendSignUp(props.userEmail)
          .catch((err) => { errorMessages.verificationCode = !err.message ? err : err.message; });
      }

      return {
        errorMessages,
        clearErrorField,
        router,
        state,

        screen,
        v$,

        validateField: (name: string) => validateField(name, v$, errorMessages),
        onSubmit,
        resendCode,
      };
    }
  });
</script>