
  import { defineComponent, reactive, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { useVuelidate } from "@vuelidate/core";
  import { useScreen } from 'vue-screen';

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppButton from '@/components/stateless/AppButton.vue';

  import { vuex } from '@/store';
  import { IErrorFields } from '@/types';
  import { verificationFormRules } from '@/views/auth/auth-validation-rules';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { authService } from '@/services';
  import { routesNames } from '@/router';

  export default defineComponent({
    name: 'Verification',

    components: { AppInput, AppButton },

    props: {
      userEmail: {
        type: String,
        required: true
      },
      
      showChangeEmailHandler: {
        type: Boolean,
        default: false
      }
    },

    emits: [ 'change-email-click' ],

    setup(props) {
      const router = useRouter();
      const screen = useScreen();
      const state = reactive<{ verificationCode: string }>({ verificationCode: '' });
      const rules = computed<any>(() => verificationFormRules);
      const v$ = useVuelidate(rules, state);

      const errorMessages = reactive<IErrorFields>({ verificationCode: '' });

      async function onSubmit () {
        if (await v$.value.$validate()) {
          vuex.setLoading(true);

          authService.confirmSignUp(props.userEmail, state.verificationCode)
            .then(() => router.push({ name: routesNames.greeting }))
            .catch((err) => { errorMessages.verificationCode = !err.message ? err : err.message; } ); 

        } else {
          handleSetErrors(v$.value.$errors, errorMessages);
        }
      }

      async function resendCode() {
        return await authService.resendSignUp(props.userEmail)
          .catch((err) => { errorMessages.verificationCode = !err.message ? err : err.message; });
      }

      return {
        errorMessages,
        clearErrorField,
        router,
        state,

        screen,
        v$,

        validateField: (name: string) => validateField(name, v$, errorMessages),
        onSubmit,
        resendCode,
      };
    }
  });
