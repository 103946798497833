<template>
  <div class="bg-white px-25 py-25 md:px-65 md:py-40 rounded-5">
    <!-- L O G I N  S T E P -->
    <div v-if="!showVerification">
      <div class="mb-40">
        <h1 class="text-dark-cl-20 text-1xl sm:text-3xl mb-15">Log in to your account</h1>
        <p class="text-grey-fp-60 text-sm sm:text-base">Enter your email and password to login</p>
      </div>

      <!-- F O R M -->
      <div v-if="!showVerification">
        <form
          data-test="login-form"
          @submit.prevent="onSubmit"
        >
          <AppInput
            v-model="v$.email.$model"
            name="email"
            label="Email Address"
            class="mb-30"
            :size="screen.width < 640 ? 'medium' : 'large'"
            :error="errorMessages.email"
            @focus="clearErrorField('email', errorMessages)"
            @blur="validateField('email')"
          />

          <AppInput
            v-model="v$.password.$model"
            name="password"
            label="Password"
            type="password"
            class="mb-30"
            :size="screen.width < 640 ? 'medium' : 'large'"
            :error="errorMessages.password || errorMessages.apiErrorMessage"
            @focus="clearErrorField(['password', 'apiErrorMessage'], errorMessages)"
          />
          <div class="flex flex-col sm:flex-row sm:items-center text-sm sm:text-base justify-between mb-10 sm:mb-40">
            <AppCheckbox
              v-model="state.keepSignIn"
              class="mb-30 sm:mb-0"
              @click="changeKeepMeSignInState"
            >
              Keep me sign in
            </AppCheckbox>

            <router-link
              :to="{ name: 'ForgotPassword'}"
              data-test="forgot-password-button"
              class="text-primary"
            >
              <p>Forgot Password?</p>
            </router-link>
          </div>

          <!-- A C T I O N S -->
          <AppButton
            type="primary"
            :size="screen.width < 640 ? 'medium' : 'large'"
            class="w-full mb-20"
          >
            Sign in
          </AppButton>

          <div class="flex items-center justify-center text-sm sm:text-base">
            <p class="text-grey-fp-60">
              Don’t have an account yet?
              <router-link
                :to="{ name: 'SignUp' }"
                class="text-primary"
              >
                Sign Up
              </router-link>
            </p>
          </div>
        </form>
      </div>
    </div>

    <!-- V E R I F I C A T I O N  S T E P -->
    <Verification
      v-else
      :user-email="state.email"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, computed, ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { useVuelidate } from "@vuelidate/core";
  import { useScreen } from 'vue-screen';
  import { CognitoUserInterface } from '@aws-amplify/ui-components';

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppCheckbox from '@/components/stateless/AppCheckbox.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import Verification from '@/views/auth/components/Verification.vue';

  import { IErrorFields, ILogInForm } from '@/types';
  import { loginFormRules } from './auth-validation-rules';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { authService, keepUserSignedIn, isUserKeptSignedIn } from '@/services';
  import { routesNames } from '@/router';
  import { vuex } from '@/store';

  export default defineComponent({
    name: 'Login',

    components: { AppInput, AppCheckbox, AppButton, Verification },

    setup() {
      const router = useRouter();
      const screen = useScreen();

      const state = reactive<ILogInForm>({ email: '', password: '', keepSignIn: true });
      const errorMessages = reactive<IErrorFields>({ email: '', password: '', apiErrorMessage: '' });
      const rules = computed<any>(() => loginFormRules);
      const v$ = useVuelidate(rules, state);
      let showVerification = ref<boolean>(false);

      async function onSubmit() {
        if (await v$.value.$validate()) {

          return authService.logIn(state)
            .then((res: CognitoUserInterface) => {
              vuex.setUser(res);
              if (vuex.user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
                router.push({ 
                  name: routesNames.createNewPassword, 
                  params: { email: state.email } 
                });
              } else {
                const redirectedPageName = vuex.isAdmin ? routesNames.adminFamilies : routesNames.root;
                router.push({ name: redirectedPageName });
              }
            })
            .catch((err: any) => handleErrors(err));
        } else {
          handleSetErrors(v$.value.$errors, errorMessages);
        }
      }

      function handleErrors(err: any) {

        if (err.code === 'UserNotConfirmedException') {
          showVerification.value = true;
        }
        else {
          errorMessages.apiErrorMessage = 'Invalid Email or Password. Please try again';
        }
      }

      function changeKeepMeSignInState() {
        keepUserSignedIn(state.keepSignIn);
      }

      function checkKeepMeSignInState() {
        // Check if this is the first time user use app from current device
        if (localStorage.getItem('keepUserSignedIn') === null) {
          state.keepSignIn = true;
        } else {
          state.keepSignIn = isUserKeptSignedIn();
        }

        changeKeepMeSignInState();
      }

      onMounted(() => {
        checkKeepMeSignInState();
      });

      return {
        errorMessages,
        showVerification,
        state,

        screen,
        v$,

        clearErrorField,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        changeKeepMeSignInState,
        onSubmit,
      };
    }
  });
</script>