
  import { defineComponent, reactive, computed, ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { useVuelidate } from "@vuelidate/core";
  import { useScreen } from 'vue-screen';
  import { CognitoUserInterface } from '@aws-amplify/ui-components';

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppCheckbox from '@/components/stateless/AppCheckbox.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import Verification from '@/views/auth/components/Verification.vue';

  import { IErrorFields, ILogInForm } from '@/types';
  import { loginFormRules } from './auth-validation-rules';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { authService, keepUserSignedIn, isUserKeptSignedIn } from '@/services';
  import { routesNames } from '@/router';
  import { vuex } from '@/store';

  export default defineComponent({
    name: 'Login',

    components: { AppInput, AppCheckbox, AppButton, Verification },

    setup() {
      const router = useRouter();
      const screen = useScreen();

      const state = reactive<ILogInForm>({ email: '', password: '', keepSignIn: true });
      const errorMessages = reactive<IErrorFields>({ email: '', password: '', apiErrorMessage: '' });
      const rules = computed<any>(() => loginFormRules);
      const v$ = useVuelidate(rules, state);
      let showVerification = ref<boolean>(false);

      async function onSubmit() {
        if (await v$.value.$validate()) {

          return authService.logIn(state)
            .then((res: CognitoUserInterface) => {
              vuex.setUser(res);
              if (vuex.user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
                router.push({ 
                  name: routesNames.createNewPassword, 
                  params: { email: state.email } 
                });
              } else {
                const redirectedPageName = vuex.isAdmin ? routesNames.adminFamilies : routesNames.root;
                router.push({ name: redirectedPageName });
              }
            })
            .catch((err: any) => handleErrors(err));
        } else {
          handleSetErrors(v$.value.$errors, errorMessages);
        }
      }

      function handleErrors(err: any) {

        if (err.code === 'UserNotConfirmedException') {
          showVerification.value = true;
        }
        else {
          errorMessages.apiErrorMessage = 'Invalid Email or Password. Please try again';
        }
      }

      function changeKeepMeSignInState() {
        keepUserSignedIn(state.keepSignIn);
      }

      function checkKeepMeSignInState() {
        // Check if this is the first time user use app from current device
        if (localStorage.getItem('keepUserSignedIn') === null) {
          state.keepSignIn = true;
        } else {
          state.keepSignIn = isUserKeptSignedIn();
        }

        changeKeepMeSignInState();
      }

      onMounted(() => {
        checkKeepMeSignInState();
      });

      return {
        errorMessages,
        showVerification,
        state,

        screen,
        v$,

        clearErrorField,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        changeKeepMeSignInState,
        onSubmit,
      };
    }
  });
